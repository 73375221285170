import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import check from "../images/check.png";
const Ux_ui_Design = () =>
{

return(
<>
<div className="web-page">
  <section className="">
    <div className="container px-0">
      <div className="row xs-column-reverse  align-items-stretch py-5">
        <div className="col-md-6 align-self-center">
          <div className="techUse-title">
            <h1><span className="sub-color">Our UI UX Design  </span>Services</h1>
          </div>
         
          <p className="text-justify">
            We are dedicated to offering exceptional UI UX design services that are suited to our clients' specific business needs. Our designers and developers take a user-centric approach to design, ensuring that every component of your project is meticulously designed, resulting in an incredible user experience.
          </p>
          <Link to="/contact" className="btn btn-s">
          Let's Talk
          </Link>
        </div>
        <div className="col-md-6">
          <img src="../assets/img/solutions/ux.jpg" alt="" srcset="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  <section className="work-process py-5">
    <div className="container px-0">
      <div className="row">
        <div className="col-md-12">
          <div className="techUse-title text-center ">
            <h1>Our <span className="sub-color"> UX UI Design
              </span> Services Process 
            </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <img src="../assets/img/solutions/UI_UX.png" className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section className="py-lg-5">
    <div className="container px-0">
      <div className="row justify-content-center pt-4">
        <div className="col-md-12 text-center">
          <div className="techUse-title text-center">
            <h1>Our Approach to  <span className="sub-color"> UI/UX Design Services</span> 
            </h1>
          </div>
          <p className="short-desc">We offer comprehensive UI/UX design services. Our approach begins with market research and analysis to better understand consumer expectations and goals, followed by a wireframe presentation of the project's essential concept. Once the wireframes are approved by the customer, we create a completely functional UI/UX and provide early-stage choices for prototype testing. Lastly, we begin the front-end development phase and conduct comprehensive UI testing to ensure that everything works properly. </p>
        </div>
      </div>
       <div className="row why-go pb-4">
        <div className="col-md-4 ml-3 ba">
          <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Market Research and Analysis</h4>
            <p>
            We begin the UX process by gathering intel and evaluating user objectives. This ensures that the solution developed is in line with the business’s specific needs. 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Concept Presentation </h4>
            <p>
            After identifying the core features of the project, we develop information architecture and present it to the client to ensure total clarity.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">UI/UX Wireframes</h4>
            <p>
            We then prototype the app or website structure, highlighting what should be featured on the app pages.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Fully Functional UX/UI </h4>
            <p>
            A fully-functional UI/UX for the application is developed after receiving the client's approval on the wireframes.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Prototype Testing </h4>
            <p>
            The clients are offered early-stage access to so they can test the designs for UI/UX functionality before we enter the final phase of development.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">UI Testing Process </h4>
            <p>
            We examine the final output for bugs and errors and provide suggestions on how to enhance its performance and functionality.
            </p>
          </div>
          </span>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1>  Our <span className="sub-color"> UI UX Design Services </span> and Capabilities
            </h1>
          </div>
        </div>
        <div className="col-md-12 text-center">
          <p className="short-desc">We take great pleasure in our high-quality UI/UX design services. We have a qualified team of designers and engineers at our disposal, who will collaborate with you to ensure that every aspect of your website or application is precisely crafted and promises exceptional user experience.</p>
        </div>
      </div>
      <div className="row py-lg-5">
        <section className="web-banner mb-4">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/dev.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pt-3">
                            <h4 className="color-bluec ">High-fidelity
                            </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We create fully interactive prototypes to demonstrate how the application/website will function in real life.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/beskope.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Wireframes
                            </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Wireframes are used to display the content, structure, and functionality of each page of a mobile or web app.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/expert.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Mobile & Web User Interface Design
                            </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We specialise in developing user interfaces that are clear, fully functional, and easy to navigate.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/outs.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Cross-Platform Design
                            </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We deliver responsive, cross-platform designs that work flawlessly across several devices and platforms, be it Andriod, IOS or Windows.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/solutions.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Technical Design
                            </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We furnish technical designs to help clients understand their project's technical workflow, such as defining the technological stack, third-party interfaces, and service and API interactions.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/account.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Software Redesign
                            </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We can help you rebuild your software from scratch, giving it a more professional look and feel. The process starts with reviewing your application and getting to know your users.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </div>
    </div>
  </section>
  
</div>
</>
)
}
export default Ux_ui_Design