import React,{useEffect} from "react";
import Select from 'react-select';
const Get_Proposal = () =>
{
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const profile = [
    { value: 'Digital marketing', label: 'Digital marketing' },
    { value: 'Web Development', label: 'Web Development' },
];
    return(
         <>
          
  <div className="web-page contact-page get-pro">
<section className="">
  <div className="container px-0">
    <div className="row  pb-lg-5 align-items-stretch xs-column-reverse">
      <div className="col-md-6 py-4 align-self-center">
        <div className="techUse-title ">
          <h1>Get <span className="sub-color">Proposal</span></h1>
        </div>
       <p>Want to take your business to the next level? Talk to our experts, helping us diagnose your specific needs, so we can curate a solution tailored keeping in mind your business objectives.</p>
       
      </div>
      <div className="col-md-6">
        <img src="../assets/img/New folder/proposal.jpg" alt="" srcset="" className="img-fluid"/>
      </div>
    </div>
  </div>
</section>


<section className="bg-light-blue py-5">
<div className="container box1 input-s">
  <div className="row align-items-stretch ">
  <div className="col-md-12 text-center pb-4" >
      <p className="proposal">Please <span className="sub-color"> provide </span> the request details and our team will filter results and will get back to you.</p>
    </div>
  <div className="col-md-5 align-self-center">
   
  <img src="/assets/img/New folder/Checklist.png" alt="" srcset="" className="img-fluid"/>
  
  </div>
  <div className="col-md-7 align-self-center">
  
  <div className="cont-s">
  <div className="brand-logo"><img src="/assets/img/logo-removebg-preview.png" alt="" className="img-fluid pt-1 pl-1"/></div>  
  <form className="inputs">
<div className="row">
  <div className="form-group col-md-6">
    <label htmlFor="name">Name <span>*</span></label>
    <input type="text" name="name" placeholder="Your Name" />
    </div>
    
    <div className="form-group col-md-6">
    <label htmlFor="email">EMAIL <span>*</span></label>
    <input type="email" name="email" placeholder="Your Email" />
    </div>
    <div className="form-group col-md-6">
    <label htmlFor="mobile">Mobile no<span>*</span></label>
    <input type="text" name="mobile" placeholder="Mobile no" />
    </div>
    <div className="form-group col-md-6">
    <label htmlFor="city">City <span>*</span></label>
    <input type="text" name="city" placeholder="City" />
    </div>
    <div className="form-group col-md-6">
    <label htmlFor="service">Service <span>*</span></label>
    <Select className='single-drop py-1' name="service" options={profile} placeholder="Select Service" />
    </div>
    <div className="form-group col-md-6">
    <label htmlFor="url">Website URL <span>*</span></label>
    <input type="text" placeholder="Website URL" name="url" />
    </div>
    <div className="form-group col-md-6">
    <label htmlFor="target">Location to target <span>*</span></label>
    <input type="text" placeholder="Location to target" name="target" />
    </div>
    <div className="form-group col-md-6">
    <label htmlFor="budget">Monthly Budget <span>*</span></label>
    <input type="text" placeholder="Monthly Budget" name="budget" />
    </div>
    <div className="form-group col-md-12">
    <label>Anything else you want to inform us? <span>*</span></label>
    <textarea name="massege" id="" cols="30" rows="3" placeholder="Type here...."></textarea>
    </div>
    </div>
    <div className="form-group text-center">
    <button type="submit" className="btnc default-btn border-0 ">SUBMIT</button>
    </div>
  </form>
 
</div>
  </div>
  </div>
</div>

</section>

{/* <section className="">
  <div className="container px-0">
    <div className="row  pb-5 align-items-stretch">
      <div className="col-md-12 py-4 align-self-center">
        <div className="techUse-title text-center">
          <h1>Google  <span className="sub-color">Reviews</span></h1>
          <p className="short-desc">Google rating score:4.8 of 5,based on 112 reviews.</p>
          <div className="reviews">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                    </div>
        </div>
            
      </div>
      <div className="testimonial-box-container">
      
            <div className="testimonial-box">
      
                <div className="box-top">
                    
                    <div className="profile">
                      
                        <div className="profile-img">
                            <img src="/assets/img/New folder/user.png" alt="" />
                        </div>
                       
                        <div className="name-user">
                            <strong>Liam mendes</strong>
                            <span>@liammendes</span>
                        </div>
                    </div>
                  
                    <div className="reviews">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                    </div>
                </div>
               
                <div className="client-comment">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                </div>
            </div>
          
            <div className="testimonial-box">
              
                <div className="box-top">
                   
                    <div className="profile">
                      
                        <div className="profile-img">
                        <img src="/assets/img/New folder/user.png" alt="" />
                        </div>
                       
                        <div className="name-user">
                            <strong>Noah Wood</strong>
                            <span>@noahwood</span>
                        </div>
                    </div>
                
                    <div className="reviews">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                    </div>
                </div>
               
                <div className="client-comment">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                </div>
            </div>
         
            <div className="testimonial-box">
             
                <div className="box-top">
                
                    <div className="profile">
                    
                        <div className="profile-img">
                        <img src="/assets/img/New folder/user.png" alt="" />
                        </div>
                       
                        <div className="name-user">
                            <strong>Oliver Queen</strong>
                            <span>@oliverqueen</span>
                        </div>
                    </div>
                   
                    <div className="reviews">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                    </div>
                </div>
              
                <div className="client-comment">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                </div>
            </div>
           
            <div className="testimonial-box">
            
                <div className="box-top">
                  
                    <div className="profile">
                     
                        <div className="profile-img">
                        <img src="/assets/img/New folder/user.png" alt="" />
                        </div>
                      
                        <div className="name-user">
                            <strong>Barry Allen</strong>
                            <span>@barryallen</span>
                        </div>
                    </div>
               
                    <div className="reviews">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                    </div>
                </div>
           
                <div className="client-comment">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, quaerat quis? Provident temporibus architecto asperiores nobis maiores nisi a. Quae doloribus ipsum aliquam tenetur voluptates incidunt blanditiis sed atque cumque.</p>
                </div>
            </div>
        </div>
     
      
    </div>
  </div>
</section> */}

</div>


         </>

    );
}
export default Get_Proposal;