import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
const Mobile_App_Marketing = () =>
{

return(
<>
<div className="web-page">
  <section className="">
    <div className="container px-0">
      <div className="row  xs-column-reverse  align-items-stretch">
        <div className="col-md-6 align-self-center">
          <div className="techUse-title">
            <h1><span className="sub-color">Mobile App </span>Marketing</h1>
          </div>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
          <Link to="/contact" className="btn btn-s">
          Let's Talk
          </Link>
        </div>
        <div className="col-md-6">
          <img src="../assets/img/solutions/app-m.jpg" alt="" srcset="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  <section className="work-process py-5">
    <div className="container px-0">
      <div className="row">
        <div className="col-md-12">
          <div className="techUse-title text-center mb-5">
            <h1>Our <span className="sub-color"> Mobile App </span> Marketing Process </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <img src="../assets/img/process.png" className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section className="py-lg-5">
    <div className="container px-0">
      <div className="row justify-content-center pt-4">
        <div className="col-md-9 text-center">
          <div className="techUse-title text-center">
            <h1>Why you should Go for <span className="sub-color"> Mobile App Marketing </span> </h1>
          </div>
          <p className="short-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
        </div>
      </div>
      <div className="row why-go pb-4">
        <div className="col-md-4 ml-3 ba">
          <span>
          <div className="hover-icon">
            <img src="/assets/img/solutions/Rich-APIs.png" alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Lorem ipsum </h4>
            <p>
              Lorem Ipsum has been the industry's standard dummy text ever dummy text ever since the 1500s 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
            <img src="/assets/img/solutions/Open-Source-Libraries.png" alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Lorem ipsum </h4>
            <p>
              Lorem Ipsum has been the industry's standard dummy text ever dummy text ever since the 1500s 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
            <img src="/assets/img/solutions/Learning-Curve.png" alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Lorem ipsum </h4>
            <p>
              Lorem Ipsum has been the industry's standard dummy text ever dummy text ever since the 1500s 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
            <img src="/assets/img/solutions/Enhanced-Compatibility.png" alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Lorem ipsum </h4>
            <p>
              Lorem Ipsum has been the industry's standard dummy text ever dummy text ever since the 1500s 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
            <img src="/assets/img/solutions/Platform-Independency.png" alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Lorem ipsum </h4>
            <p>
              Lorem Ipsum has been the industry's standard dummy text ever dummy text ever since the 1500s 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
            <img src="/assets/img/solutions/Rapid-Development-Tools.png" alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Lorem ipsum </h4>
            <p>
              Lorem Ipsum has been the industry's standard dummy text ever dummy text ever since the 1500s 
            </p>
          </div>
          </span>
        </div>
      </div>
    </div>
  </section>
  <section className="py-5 price mb-5">
    <div className="">
      <div className="container">
        <h1 className=" text-center pb-5"> <span className="sub-color">Mobile App Marketing</span> Packages</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6 price-style">
            <div className="pricingTable10">
              <div className="pricingTable-header">
                <h3 className="heading">Business</h3>
                <span className="price-value">
                <span className="currency">₹</span> 20
                <span className="month">/mo</span>
                </span>
              </div>
              <div className="pricing-content">
                <ul>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>50GB Disk Space</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>50 Email Accounts</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>50GB Monthly Bandwidth</li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>10 Subdomains</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>15 Domains</li>
                </ul>
                <a href="#" className="read">sign up</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 price-style">
            <div className="pricingTable10">
              <div className="pricingTable-header">
                <h3 className="heading">Premium</h3>
                <span className="price-value">
                <span className="currency">₹</span> 30
                <span className="month">/mo</span>
                </span>
              </div>
              <div className="pricing-content">
                <ul>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>50GB Disk Space</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>50 Email Accounts</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>50GB Monthly Bandwidth</li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>10 Subdomains</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>15 Domains</li>
                </ul>
                <a href="#" className="read">sign up</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 price-style">
            <div className="pricingTable10">
              <div className="pricingTable-header">
                <h3 className="heading">Extra</h3>
                <span className="price-value">
                <span className="currency">₹</span> 40
                <span className="month">/mo</span>
                </span>
              </div>
              <div className="pricing-content">
                <ul>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>50GB Disk Space</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>50 Email Accounts</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>50GB Monthly Bandwidth</li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>10 Subdomains</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>15 Domains</li>
                </ul>
                <a href="#" className="read">sign up</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1>  Why Choose <span className="sub-color">BFC Softtech</span> as a <span className="sub-color"> Mobile App Marketing </span> Company? </h1>
          </div>
        </div>
        <div className="col-md-8 text-center ">
          <p className="short-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
        </div>
      </div>
      <div className="row py-lg-5">
        <section className="web-banner mb-4">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/dev.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pt-3">
                            <h4 className="color-bluec ">Expert Mobile App Developers </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/expert.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Mobile App Marketing Expertise </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </div>
    </div>
  </section>
  <section className="pb-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="techUse-title text-center mb-5">
            <h1>Your <span className="sub-color"> FAQ's </span>  </h1>
            <p className="short-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
          </div>
        </div>
      </div>
       <Accordion className="border-0" allowZeroExpanded={"true"}>
        <div className="row">
          <div className="col-md-6">
            <AccordionItem>
              <AccordionItemHeading className="bg-light-blue">
                <AccordionItemButton>
                  What harsh truths do you prefer to ignore?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Exercitation in fugiat est ut ad ea cupidatat ut in
                  cupidatat occaecat ut occaecat consequat est minim minim
                  esse tempor laborum consequat esse adipisicing eu
                  reprehenderit enim.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Is free will real or just an illusion?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  In ad velit in ex nostrud dolore cupidatat consectetur
                  ea in ut nostrud velit in irure cillum tempor laboris
                  sed adipisicing eu esse duis nulla non.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </div>
          <div className="col-md-6">
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What harsh truths do you prefer to ignore?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Exercitation in fugiat est ut ad ea cupidatat ut in
                  cupidatat occaecat ut occaecat consequat est minim minim
                  esse tempor laborum consequat esse adipisicing eu
                  reprehenderit enim.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Is free will real or just an illusion?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  In ad velit in ex nostrud dolore cupidatat consectetur
                  ea in ut nostrud velit in irure cillum tempor laboris
                  sed adipisicing eu esse duis nulla non.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </div>
        </div>
      </Accordion>
    </div>
  </section>
</div>
</>
)
}
export default Mobile_App_Marketing