import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import check from "../images/check.png";
const Seo = () =>
{

return(
<>
<div className="web-page">
  <section className="">
    <div className="container px-0">
      <div className="row  xs-column-reverse  align-items-stretch py-5">
        <div className="col-md-6 align-self-center">
          <div className="techUse-title">
            <h1><span className="sub-color">SEO </span>Services </h1>
          </div>
          <p>We optimise your website for search engines comprehensively, boosting your search engine results and attracting more visitors to your site. Our team of experts employs the latest optimisation strategies, including keyword research, on-page optimisation, and link building, to help you accomplish the results you require for sure-shot business growth.</p>
          <Link to="/contact" className="btn btn-s">
          Let's Talk
          </Link>
        </div>
        <div className="col-md-6">
          <img src="../assets/img/solutions/seo.jpg" alt="" srcset="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  <section className="work-process py-5">
    <div className="container px-0">
      <div className="row">
        <div className="col-md-12">
          <div className="techUse-title text-center ">
            <h1>Our <span className="sub-color">Search Engine </span> Optimization  Process </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <img src="../assets/img/solutions/Search.png" className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section className="py-lg-5">
    <div className="container px-0">
      <div className="row justify-content-center pt-4">
        <div className="col-md-12 text-center">
          <div className="techUse-title text-center">
            <h1> Types Of  <span className="sub-color">SEO Services  </span> We Offer</h1>
          </div>
          <p className="short-desc">Search Engine Optimization (SEO) is an essential digital marketing strategy for businesses looking to increase their online visibility and drive more traffic to their website. However, not all businesses have the expertise or resources needed to execute an effective SEO campaign on their own. That's what we can help with. Listed below are the services we offer: </p>
        </div>
      </div>
      <div className="row why-go pb-4">
        <div className="col-md-4 ml-3 ba">
          <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">On-page optimization</h4>
            <p>
            On-page optimization involves upgrading a website's content and structure to make it more visible and relevant to search engines. This includes keyword research and analysis, meta tag optimization, website speed optimization, and ensuring that the website is mobile-friendly. 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Technical SEO</h4>
            <p>
            Technical SEO concerns working the technical characteristics of a website to propel its search engine ranking. This involves increasing website performance, guaranteeing website security, ensuring the absence of any technical issues, and improving the overall user experience.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Local SEO</h4>
            <p>
            Local SEO focuses on upgrading a company's online presence for local searches. This includes improving the company's Google My Business profile, establishing local citations and backlinks, and customizing website content to incorporate local keywords.
            </p>
          </div>
          </span>
        </div>
        </div>
        <div className="row why-go justify-content-center">
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">E-commerce SEO </h4>
            <p>
            E-commerce SEO is aimed at helping online stores improve their search engine presence and, as a result, driving more sales. This involves enhancing website speed, optimising product descriptions, and incorporating structured data. 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Content marketing </h4>
            <p>
            Content marketing is an essential component of any SEO strategy. It includes developing high-quality, engaging content that generates traffic for a website and establishes the company as an industry leader.
            </p>
          </div>
          </span>
        </div>
       
      </div>
    </div>
  </section>
  <section className="py-5 price mb-5">
    <div className="">
      <div className="container">
        <h1 className=" text-center pb-5">Our <span className="sub-color"> SEO Service </span> Pricing Plans</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6 price-style">
            <div className="pricingTable10">
              <div className="pricingTable-header">
                <h3 className="heading">SEO Packages Small Business </h3>
                <span className="price-value">
                <span className="currency">₹</span>15,000 <span className="month">/Monthly</span><br/>
                
                <span className="month">EXCLUSIVE OF ALL TAXES</span>
                </span>
              </div>
              <div className="pricing-content">
                <ul>
                  <li className="sub-color font-weight-bold text-center">WEBSITE REVIEW & ANALYSIS</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Max 5 Keywords </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Website & Competitor Analysis </li>
                  <li><i className="fa fa-check   pr-2" aria-hidden="true"></i>	Content Duplicity Check</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Initial Ranking Report </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Keywords Research  </li>
                  <li className="sub-color font-weight-bold text-center">ON PAGE SEO ANALYSIS</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Meta Tags Creation  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Canonicalization  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		URL Structure  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Content Optimization  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Image Optimization   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Heading Tag Optimization   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Website Speed Optimization </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Robots.txt </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Sitemap Creation   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Google Analytics & Search Console Setup   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Blog Optimization – 2 Posts   </li>
                  <li className="sub-color font-weight-bold text-center">LOCAL SEO SETUP</li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>	Google Map Integration on website   </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>			Google My Business Page Setup and Optimization  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Local Citations – 5  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Local Classifieds – 2  </li>
                  <li className="sub-color font-weight-bold text-center">CONTENT MARKETING</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Blog Posting (500 – 700 words) – 1</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Article Writing(500 – 700 words) – 1  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Onsite Blog (300 – 500 words) – 1   </li>
                  <li className="sub-color font-weight-bold text-center">LOCAL SEO SETUP</li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>		Guest Blogging   </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>		Broken Backlink Building  </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>	Resource Link Building   </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>	Alerts and Mention    </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>		Link Roundups   </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>			Competitor Backlink Research    </li>
                  <li className="sub-color font-weight-bold text-center">OFF PAGE SEO</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Social Sharing – 30(Total) </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Blog Social Sharing   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Slide Submissions – 1    </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Text Infographic Creation – 1   </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>	Video Creation   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Q & A – 1    </li>
                  <li className="sub-color font-weight-bold text-center">MONTHLY REPORTING</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Keyword Ranking Report </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Google Analytics Report  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Acquired Links Report    </li>
                  <li className="sub-color font-weight-bold text-center">CLIENT SUPPORT</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Email  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Chat  </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>		Call   </li>
                </ul>
                <a href="#" className="read">Get Started Now</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 price-style">
            <div className="pricingTable10">
              <div className="pricingTable-header">
                <h3 className="heading">Mid-Size Business SEO Packages</h3>
                <span className="price-value">
                <span className="currency">₹</span> 25,000 
                <span className="month">/monthly</span><br/>
                
                <span className="month">EXCLUSIVE OF ALL TAXES</span>
                </span>
              </div>
              <div className="pricing-content">
                <ul>
                  <li className="sub-color font-weight-bold text-center">WEBSITE REVIEW & ANALYSIS</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Max 15 Keywords </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Website & Competitor Analysis </li>
                  <li><i className="fa fa-check   pr-2" aria-hidden="true"></i>	Content Duplicity Check</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Initial Ranking Report </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Keywords Research  </li>
                  <li className="sub-color font-weight-bold text-center">ON PAGE SEO ANALYSIS</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Meta Tags Creation  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Canonicalization  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		URL Structure  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Content Optimization  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Image Optimization   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Heading Tag Optimization   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Website Speed Optimization </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Robots.txt </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Sitemap Creation   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Google Analytics & Search Console Setup   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Blog Optimization – 5 Posts   </li>
                  <li className="sub-color font-weight-bold text-center">LOCAL SEO SETUP</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Google Map Integration on website   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Google My Business Page Setup and Optimization  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Local Citations – 10  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Local Classifieds – 5  </li>
                  <li className="sub-color font-weight-bold text-center">CONTENT MARKETING</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Blog Posting (500 – 700 words) – 2</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Article Writing(500 – 700 words) – 2  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Onsite Blog (300 – 500 words) – 2   </li>
                  <li className="sub-color font-weight-bold text-center">LOCAL SEO SETUP</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Guest Blogging   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Broken Backlink Building  </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>	Resource Link Building   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Alerts and Mention    </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>		Link Roundups   </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>			Competitor Backlink Research    </li>
                  <li className="sub-color font-weight-bold text-center">OFF PAGE SEO</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Social Sharing – 40(Total) </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Blog Social Sharing   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Slide Submissions – 2    </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Text Infographic Creation – 2   </li>
                  <li><i className="fa fa-times  pr-2" aria-hidden="true"></i>	Video Creation   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Q & A – 3    </li>
                  <li className="sub-color font-weight-bold text-center">MONTHLY REPORTING</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Keyword Ranking Report </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Google Analytics Report  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Acquired Links Report    </li>
                  <li className="sub-color font-weight-bold text-center">CLIENT SUPPORT</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Email  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Chat  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Call   </li>
                </ul>
                <a href="#" className="read">Get Started Now</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 price-style">
            <div className="pricingTable10">
              <div className="pricingTable-header">
                <h3 className="heading">Large Business SEO Packages</h3>
                <span className="price-value">
                <span className="currency">₹</span>40,000  
                <span className="month">/monthly</span><br/>
                
                <span className="month">EXCLUSIVE OF ALL TAXES</span>
                </span>
              </div>
              <div className="pricing-content">
                <ul>
                  <li className="sub-color font-weight-bold text-center">WEBSITE REVIEW & ANALYSIS</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Max 25 Keywords </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Website & Competitor Analysis </li>
                  <li><i className="fa fa-check   pr-2" aria-hidden="true"></i>	Content Duplicity Check</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Initial Ranking Report </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Keywords Research  </li>
                  <li className="sub-color font-weight-bold text-center">ON PAGE SEO ANALYSIS</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Meta Tags Creation  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Canonicalization  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		URL Structure  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Content Optimization  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Image Optimization   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Heading Tag Optimization   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Website Speed Optimization </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Robots.txt </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Sitemap Creation   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Google Analytics & Search Console Setup   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Blog Optimization – 5 Posts   </li>
                  <li className="sub-color font-weight-bold text-center">LOCAL SEO SETUP</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Google Map Integration on website   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Google My Business Page Setup and Optimization  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Local Citations – 15  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Local Classifieds – 10  </li>
                  <li className="sub-color font-weight-bold text-center">CONTENT MARKETING</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Blog Posting (500 – 700 words) – 2</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Article Writing(500 – 700 words) – 4  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Onsite Blog (300 – 500 words) – 2   </li>
                  <li className="sub-color font-weight-bold text-center">LOCAL SEO SETUP</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Guest Blogging   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Broken Backlink Building  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Resource Link Building   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Alerts and Mention    </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Link Roundups   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Competitor Backlink Research    </li>
                  <li className="sub-color font-weight-bold text-center">OFF PAGE SEO</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Social Sharing – 50(Total) </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>			Blog Social Sharing   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Slide Submissions – 2    </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Text Infographic Creation – 3   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Video Creation   </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Q & A – 5    </li>
                  <li className="sub-color font-weight-bold text-center">MONTHLY REPORTING</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Keyword Ranking Report </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Google Analytics Report  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>	Acquired Links Report    </li>
                  <li className="sub-color font-weight-bold text-center">CLIENT SUPPORT</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Email  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Chat  </li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>		Call   </li>
                </ul>
                <a href="#" className="read">Get Started Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1>  Why avail <span className="sub-color"> SEO Services </span> from <span className="sub-color">BFC Softtech</span>?  </h1>
          </div>
        </div>
        <div className="col-md-12 text-center ">
          <p className="short-desc">As a business owner, you surely must be aware of the significance of having a strong internet presence in the present digital ecosystem. Having a website is necessary, but it is not sufficient. You must make certain that it is visible to your targeted users. This is where BFC Softtech can help your business. 
Here are some of the reasons why BFC Softtech should be your go-to SEO partner:</p>
        </div>
      </div>
      <div className="row py-lg-5">
        <section className="web-banner mb-4">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/expert.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pt-3">
                            <h4 className="color-bluec ">Tailored SEO Services </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We recognise that no two businesses are the same. As a result, we provide personalised SEO solutions that are suited to your individual needs. We examine your website, find areas that need improvement, and develop a strategy to assist you reach your goals.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/dev.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Experienced Professionals</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Our SEO team has years of expertise in the sector. We stay current on industry trends and procedures to ensure that we deliver the best possible results to our clients.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/transperancy.png" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Transparent Reporting</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We believe in accountability and transparency. That is why we provide frequent reports on the status of your SEO campaign. You'll know precisely what we're doing and how it is benefitting you.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/account.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Competitive Pricing</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We believe in giving our clients good value for money. As a result, our SEO packages are reasonably priced, and do not compromise on quality.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </div>
    </div>
  </section>
 
</div>
</>
)
}
export default Seo

