import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import { FaCog } from "react-icons/fa";
const Portfolio = () =>
{
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
return(
<>
<style>
    {`    
    .box-shadow
{
	-webkit-box-shadow: 0 1px 1px rgba(72,78,85,.6);
	box-shadow: 0 1px 1px rgba(72,78,85,.6);
	-webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-ms-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
    cursor:pointer;
}
.box-shadow:hover
{
	-webkit-box-shadow: 0 20px 40px rgba(72,78,85,.6);
	box-shadow: 0 20px 40px rgba(72,78,85,.6);
	-webkit-transform: translateY(-15px);
	-moz-transform: translateY(-15px);
	-ms-transform: translateY(-15px);
	-o-transform: translateY(-15px);
	transform: translateY(-15px);
}
.card
{
	border-radius: 25px;
	
}

.card img
{
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
}

.portfolio .card svg
{
    font-size:62px;
	position:absolute;
	top:24rem;
  -webkit-transition: all .2s ease-out;
	-moz-transition: all .2s ease-out;
	-ms-transition: all .2s ease-out;
	-o-transition: all .2s ease-out;
	transition: all .2s ease-out;
	filter:drop-shadow(2px -1px 4px rgba(0, 69, 134, 0.2));
}
.card:hover svg
{
	filter:drop-shadow(2px -1px 4px rgba(0, 69, 134, 0.4));
}

.i
{
	position:absolute;
	top: 18rem;
	right: 2rem;
	color:white;	
	background: #d33f42;
	padding: 1rem;
	border-radius: 100%;
	transition: all .6s ease-in-out;
}

.card:hover .i
{
	transform: rotate(-180deg);
}

.i:hover
{
	box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.7) 0px 18px 36px -18px inset;
}   
    
    `}
</style>
<div className="web-page portfolio">
<section className="">
  <div className="container px-0">
    <div className="row pb-5 align-items-stretch xs-column-reverse">
      <div className="col-md-6 py-4 align-self-center">
        <div className="techUse-title ">
          <h1>Portfolio</h1>
        </div>
        <p className="text-justify">Over the years, BFC Softtech has helped numerous businesses across industries scale up their operations and achieve online success. As a result, our team possesses extensive proven experience in providing customised solutions tailored to meet the unique needs of all types of businesses. We pride ourselves on our ability to deliver exceptional results and help our clients thrive in the digital landscape.</p>
       
      </div>
      <div className="col-md-6">
      <img src="/assets/img/solutions/porfolio.png" alt="" srcset="" className="img-fluid"/>      
      </div>
    </div>
  </div>
</section>
<section className="bg-light-blue py-5">  
    <div className="container pb-5">
        <div className="text-center">
            <h1>Project  <span className="sub-color">Showcase</span></h1>
            <p>Explore our Project Showcase to discover how we've helped businesses like yours find online success.</p>
        </div>
  <div className="row justify-content-center">
    
    <div className="col-12 col-lg-6">
      <div className="card box-shadow mx-auto my-5">
      <img src="../assets/img/portfolio/Koshala literature festival.png" className="card-img-top" alt="..."/>      
      <div className="text-center portfolio py-4">
        <hr/>
          <h3 className="">Koshala Literature Festival</h3>    
         
        </div>

        <Link to=""><FaCog className="i"/></Link>
      </div>
    </div>
    
    <div className="col-12 col-lg-6">
      <div className="card box-shadow my-5 mx-auto" >
      <img src="../assets/img/portfolio/Orgeen.png" className="card-img-top" alt="..."/>
        <div className="text-center portfolio py-4">
        <hr/>
          <h3 className="">Orgeen</h3>    
         
        </div>

        <Link to=""><FaCog className="i fa-3x"/></Link>
      </div>
    </div>
    <div className="col-12 col-lg-6">
      <div className="card box-shadow my-5 mx-auto" >
      <img src="../assets/img/portfolio/Publications.png" className="card-img-top" alt="..."/>
        <div className="text-center portfolio py-4">
        <hr/>
          <h3 className="">BFC Publications</h3>    
         
        </div>

        <Link to=""><FaCog className="i fa-3x"/></Link>
      </div>
    </div>
    
    <div className="col-12 col-lg-6">
      <div className="card box-shadow my-5 mx-auto" >
      <img src="../assets/img/portfolio/Capital.png" className="card-img-top" alt="..."/>
        <div className="text-center portfolio py-4">
        <hr/>
          <h3 className="">BFC Capital</h3>    
         
        </div>

        <Link to=""><FaCog className="i fa-3x"/></Link>
      </div>
    </div>
  </div>
</div>
</section>
</div>
</>
)
}
export default Portfolio