import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
const Software_Development = () =>
{

return(
<>
<div className="web-page">
  <section className="">
    <div className="container px-0">
      <div className="row xs-column-reverse  align-items-stretch">
        <div className="col-md-6 align-self-center">
          <div className="techUse-title">
            <h1><span className="sub-color">Software </span>Development</h1>
          </div>
          <p>At BFC Softtech, we are committed to providing personalised services that help businesses achieve their objectives and stay ahead of the competition. Our developers, designers, and testers deploy cutting-edge technologies to ensure that the solution developed for your business meets industry standards and surpasses expectations.</p>
          <Link to="/contact" className="btn btn-s">
          Let's Talk
          </Link>          
        </div>
        <div className="col-md-6 py-5">
          <img src="../assets/img/solutions/software.jpg" alt="" srcset="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  <section className="work-process py-5">
    <div className="container px-0">
      <div className="row">
        <div className="col-md-12">
          <div className="techUse-title text-center mb-2">
            <h1>Our <span className="sub-color"> Software </span> Development Process </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <img src="../assets/img/solutions/Software-dev-pro.png" className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
  {/* <section className="py-lg-5">
    <div className="container px-0">
      <div className="row justify-content-center pt-4">
        <div className="col-md-12 text-center">
          <div className="techUse-title text-center">
            <h1><span className="sub-color"> Software Development </span> in Lucknow  </h1>
          </div>
       
        </div>
      </div>
      <div className="row why-go pb-4">
       <ul className="sdil px-sm-4">
        <li>Lucknow, the Nawabi capital, has a rich cultural past and is famed for its historical buildings and delectable cuisine. Aside from that, the city is rising as a centre for software development firms. The city's information technology business is rapidly evolving, opening up new opportunities for young and bright people.</li>
        <li>BFC Softtech, a prominent facilitator of new technological solutions, is one such emerging software development company. We have been fulfilling the demands of clients from diverse industries with a staff of seasoned and competent developers. Our expertise in software development, combined with our passion for innovation, has helped us earn a reputation as a trustworthy technology partner for businesses.</li>
        <li>We provide a comprehensive range of IT services, including custom software development, mobile app development, web development, and e-commerce solutions. Our developers use cutting-edge technologies and tools to create solutions that are tailored to the demands of enterprises. They have a thorough understanding of the software development process and are dedicated to delivering high-quality software solutions on time.</li>
        <li>
        One of the primary benefits of working with is our emphasis on quality. Our team deploys rigorous testing methods to ensure that the software solutions they produce are error-free and of the highest quality. This approach has aided us in establishing a solid reputation in the software development space.
        </li>
        

       </ul>
       <p className="px-sm-4">So, if you're hunting for a trustworthy software development firm in Lucknow, look no further than BFC Softtech. Contact us today to learn more about how we can assist you in achieving your business objectives.</p>
      </div>
    </div>
  </section> */}
  <section className="pt-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1>  Why choose <span className="sub-color">BFC Softtech</span> as your <span className="sub-color"> Software Development </span>  company?   </h1>
          </div>
        </div>
        <div className="col-md-12 text-center ">
          <p className="short-desc">Software solutions have evolved into a crucial tool for firms seeking to remain competitive and effective in today's business environment. Companies require a dependable software development partner who can produce high-quality solutions that match their specific demands, whether it's custom software development, mobile app development, web development, or e-commerce solutions.
We are a well-known software development firm with a reputation for providing innovative and dependable software solutions to businesses of all types and sizes. We possess the abilities and experience needed to deliver software solutions that help businesses achieve their goals, thanks to our team of experienced developers and designers.
<br/>Here are some of the major reasons why businesses select us as their software development partner:</p>
        </div>
      </div>
      <div className="row py-lg-5">
        <section className="web-banner mb-4">
          <Carousel>
            <Carousel.Item>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/migration.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pt-3">
                            <h4 className="color-bluec ">Focus on Quality </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Quality is our top priority, and we have a strict quality assurance procedure in place to ensure that every software solution we deliver is error-free and satisfies the highest industry standards. Our developers use cutting-edge technology and techniques to create solutions that are not only practical but also visually appealing.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/outs.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Personalized Service </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Because we understand that each client's needs are unique, we work closely with each of them to understand their exact wants and requirements before proposing a solution. Following that, our developers and designers apply their knowledge and experience to create bespoke solutions that fit the needs of the client, assisting businesses in achieving their objectives.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/solutions.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Cutting-Edge Solutions </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Our developers are always on top of the latest advances in software development, and they use their knowledge and experience to develop cutting-edge solutions for our clients, allowing them to stay ahead of the competition.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/account.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Cost-Effective Solutions</h4>
                          </div>
                          <div className="banner-disc">
                            <p className=""> We provide cost-effective software solutions that help organisations achieve their objectives without straining their budgets. Our adaptable pricing strategy enables businesses to select the number and level of services that best suits their requirements and budget.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/delivary.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">On-Time Delivery</h4>
                          </div>
                          <div className="banner-disc">
                            <p className=""> We have a proven track record of delivering software solutions on time. Our staff collaborates closely with clients to understand their demands and create a timeline that matches their individual needs.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </div>
    </div>
  </section>
  
</div>
</>
)
}
export default Software_Development