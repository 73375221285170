import React,{useEffect} from "react";
const Contact = () =>
{
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return(
         <>
          
          <div className="web-page contact-page">
<section className="">
  <div className="container px-0">
    <div className="row  pb-5 align-items-stretch xs-column-reverse">
      <div className="col-md-6 py-4 align-self-center">
        <div className="techUse-title ">
          <h1>Contact<span className="sub-color"> Us</span></h1>
        </div>
       <p className="text-justify">Transform your business with BFC Softtech - the
technology experts in automation, computing, and
digital/social media marketing. Streamline and automate
your operations for success with our out-of-the box
solutions.</p>
       
      </div>
      <div className="col-md-6">
        <img src="/assets/img/solutions/contact.jpg" alt="" srcset="" className="img-fluid"/>
      </div>
    </div>
  </div>
</section>


<section className="bg-light-blue py-5">
<div className="container box1 input-s">
  <div className="row align-items-stretch ">
  <div className="col-md-7 px-5">
    <div className=" text-center pb-4" >
      <h1>Customer <span className="sub-color">Support </span></h1>
    </div>

    <div className="row">
      <div className="col-md-2">
      <a className="text-black" href="tel:9511115759" target="_blank">  <div className="brand-logo brand"><i className="fa fa-phone fa-2x " aria-hidden="true"></i></div>  </a>
      </div>
      <div className="col-md-10 text-xs-center">
    <h5 className="pt-4"><a className="text-black" href="tel:9511115759" target="_blank">+91-9511115759 </a></h5>  
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-md-2">
      <a href="https://wa.me/9511115759" target="_blank" >
      <div className="brand-logo brand"><i className="fa fa-whatsapp fa-2x " aria-hidden="true"></i></div>  
      </a>
      </div>
      <div className="col-md-10 text-xs-center">
      <h5 className="pt-4"><a href="https://wa.me/9511115759" target="_blank" >+91-9511115759</a></h5>  
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-md-2">
      <a href="mailto:support@bfcsofttech.com">
      <div className="brand-logo brand"><i className="fa fa-envelope fa-2x " aria-hidden="true"></i></div>  
      </a>
      </div>
      <div className="col-md-10 al text-xs-center">
      <h5 className="pt-4"><a href="mailto:support@bfcsofttech.com">support@bfcsofttech.com</a></h5>  
      </div>
    </div>
    <div className=" text-center pt-4">
      <h1>Address</h1>
    </div>
    <div className="row mt-3">
      <div className="col-md-2">
      <a href="https://goo.gl/maps/98Ufx6ubQCAuK7vw5" target="_blank" >
      <div className="brand-logo brand"><i className="fa fa-map-marker fa-2x pl-4" aria-hidden="true"></i></div>  
    </a>
      </div>
      <div className="col-md-10 al text-xs-center">
      <h5 className="pt-4"><a href="https://goo.gl/maps/98Ufx6ubQCAuK7vw5" target="_blank" >CP-61, Viraj Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010</a></h5>  
      </div>
    </div>
  </div>
  <div className="col-md-5 align-self-center">
  <div className="cont-s">
  <div className="brand-logo"><img src="/assets/img/logo-removebg-preview.png" alt="" className="img-fluid pt-1 pl-1"/></div>  
  <form className="inputs">
  <div className="form-group">
    <label htmlFor="name">Name</label>
    <input type="text" name="name" placeholder="Enter Your Name" />
    </div>
    <div className="form-group">
    <label htmlFor="email">EMAIL</label>
    <input type="email" name="email" placeholder="Your Email" />
    </div>
    
    <div className="form-group">
    <label htmlFor="massege">MASSAGE</label>
    <textarea name="massege" id="" cols="30" rows="3" placeholder="Type here...."></textarea>
    </div>
    <div className="form-group text-center">
    <button type="submit" className="btnc default-btn border-0 ">SUBMIT</button>
    </div>
  </form> 
</div>
  </div>
  </div>
</div>
</section>

<section className="py-5">
  <div className="container">
    <div className="row">
      <div className="col-md-12">      
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14238.473501374268!2d81.022761!3d26.8520878!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x937668bdbf8ad64a!2sBFC%20Softtech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1673423422867!5m2!1sen!2sin" width="100%" height="400" style={{border:"4px solid #ecf0f3" ,borderRadius:"10px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </div>
</section>
</div>


         </>

    );
}
export default Contact;