import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaTimes } from "react-icons/fa";
function CareerWebDev() {
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
return (
<>
<a href="#" class=" rounded py-2 text-poppins" onClick={handleShow}>Read More</a>
<Modal show={show} onHide={handleClose} className=""  centered>
  <Modal.Header className='bg-light'>
    <div className="col-md-8 px-0">
      <h5 className='text-poppins'>Job Description</h5>
    </div>
    <div className="col-md-4 text-right">
      <a href='#' className="text-danger" onClick={handleClose}>
        <FaTimes/>
      </a>
    </div>
  </Modal.Header>
  <Modal.Body>
    <div className='read-main'>
      <p className='text-rale'>  Job Title  : <span className='sub-color pl-2'>Web Developer</span> </p>
      <ul className='pl-4 text-poppins'>
        <li>Candidate should be able to develop logic and code independently. </li>
        <li>Candidate should be experienced in React/NodeJs/MYSQL,Mongo DB, JavaScript , jquery or any of the above technologies or framework.
</li>
        <li>Candidate should be able to Deploy Web applications on Servers using AWS/Azure or any other deployment technology and should be familiar with any VCS (Version Control System) like GitHub/GitLab.</li>
        <li>Web designing knowledge will be an added advantage.</li>
        <li>Candidate profile Degree - B-Tech/ B.E./BCA/MCA preferable full time only. </li>
        
        <li>A person with bright ideas and clear goals with Proficiency in ReactJs, NodeJS, MySQL, jQuery, JavaScript, CSS, HTML, Angular, Mango DB and Core PHP is a plus.</li>
       
      </ul>
      
      <p className='text-rale'>Desired Skills and Experience :</p>
      <ul className='pl-4 text-poppins'>
        <li>JS,css , jquery , javascript , cake , web developer , NodeJs, , mysql , html , React programmer, AWS Services</li>
        
      </ul>
      
     
      <h6>  Exp:   <span className='sub-color'>2-4 years </span></h6>
      <h6> Salary: <span className='sub-color'>30k-40k</span></h6>
      <h6 > Location: <span className='sub-color'>CP-61 Viraj Khand, Gomti Nagar, Lucknow </span></h6>
      <h6> Work from office: <span className='sub-color'> 9:30AM - 6:30 PM</span></h6>
      <h6> Job Type: <span className='sub-color'> Full time</span></h6>
     
      <p>You can send in your resume to  <a href='#'> hrd@bfcsofttech.com</a></p>
    </div>
  </Modal.Body>
</Modal>
</>
);
}
export default CareerWebDev