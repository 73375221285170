import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import check from "../images/check.png";
const Ppc = () =>
{

return(
<>
<div className="web-page">
  <section className="">
    <div className="container px-0">
      <div className="row py-5 xs-column-reverse  align-items-stretch">
        <div className="col-md-6 align-self-center">
          <div className="techUse-title ">
            <h1><span className="sub-color">Pay-Per-Click Management </span>Services</h1>
          </div>
          <p>With our skilled pay-per-click (PPC) management services, you can increase traffic and conversions. We customise campaigns based on your objectives and budget, handling everything from keyword research to ad copy. Also, our thorough analytics and reporting help you to maximise your ROI while eliminating needless spending.</p>
          <Link to="/contact" className="btn btn-s">
          Let's Talk
          </Link>
        </div>
        <div className="col-md-6">
          <img src="../assets/img/solutions/sem.jpg" alt="" srcset="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  
  <section className="py-lg-5">
    <div className="container px-0">
      <div className="row justify-content-center pt-4">
        <div className="col-md-12 text-center">
          <div className="techUse-title text-center">
            <h1>Why you should opt for <span className="sub-color"> Pay-Per-Click Management   </span> Services</h1>
          </div>
          <p className="short-desc">Pay-per-click (PPC) advertising is a popular method of acquiring highly relevant leads and increase website traffic. The right PPC management partner can help you laser-target your audience and filter out uninterested prospects. </p>
        </div>
      </div>
       <div className="row why-go pb-4">
        <div className="col-md-4 ml-3 ba">
          <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Cost Effectiveness </h4>
            <p>
            You have complete control over your budget, ad placements, and targeting with PPC advertising, making it a cost-effective option to market your brand online. This adaptability allows you to modify your campaigns as needed and deploy your budget where it will have the greatest impact.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Quick Results </h4>
            <p>
            PPC advertising is an excellent way to drive immediate traffic to your website. Unlike other techniques of online marketing, which can take weeks or months to show results, PPC can provide targeted visits to your website in minutes.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Real-time Monitoring </h4>
            <p>
            PPC campaigns facilitate real-time monitoring of results, allowing you complete control over your advertising budget. You can track your campaign performance continuously and tweak or modify them until you achieve your desired return on investment (ROI). This means you only pay for ads that fetch results.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Social Media Presence </h4>
            <p>
            PPC advertising can also help augment your social media marketing efforts. By leveraging Pay-Per-Click advertisements, you can promote your business on all major social media platforms such as Facebook, Twitter, and LinkedIn, and reach out to a much wider audience.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Intelligent Retargeting</h4>
            <p>
            You can use Pay-Per-Click advertising to implement intelligent retargeting techniques and reach out to potential customers who did not convert on their first visit to your website. Retargeting campaigns are an essential component of any successful PPC campaign because of their effectiveness.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Fast Deployment </h4>
            <p>
            PPC campaigns can be created and launched in a matter of hours. You can launch a new Pay-Per-Click campaign and start generating traffic and fresh leads the same day you launch a new product or service, making it a rapid and effective way to grow your business online.
            </p>
          </div>
          </span>
        </div>
      </div>
    </div>
  </section>
  <section className="py-5 price mb-5">
    <div className="">
      <div className="container">
        <h1 className=" text-center pb-5"> <span className="sub-color">PPC Services</span> Packages</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6 price-style">
            <div className="pricingTable10">
              <div className="pricingTable-header">
                <h3 className="heading">PPC Packages Basic
                </h3>
                <span className="price-value">
                <span className="currency">₹</span>10,000 
                <span className="month">/monthly</span><br />
                <span className="month">EXCLUSIVE OF ALL TAXES</span>
                </span>
              </div>
              <div className="pricing-content">
                <ul>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Campaign - 1</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>AD Groups - 2</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Ad Copies - Maximum 5</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>No. of Keywords-25</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Search Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Display Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Gmail Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Video Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Shopping Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>App Promotion Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Remarketing List</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Conversion Tracking</li>
                  <li className="sub-color font-weight-bold text-center">PPC CAMPAIGN MANAGEMENT</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Competitor Analysis - Yes</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Keyword Optimization - Yes</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Ad Copy Optimization - Yes</li>
                  <li className="sub-color font-weight-bold text-center">VALUE ADDS</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>ROI Analysis</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Landing Page Optimization</li>
                  <li className="sub-color font-weight-bold text-center">REPORTING</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Reports - Monthly</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Dedicated Account Manager</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Support - Email, Chat</li>
                </ul>
                <a href="#" className="read">Get Started Now</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 price-style">
            <div className="pricingTable10">
              <div className="pricingTable-header">
                <h3 className="heading">Advanced PPC Packages
                </h3>
                <span className="price-value">
                <span className="currency">₹</span> 18,000 
                <span className="month">/monthly</span><br />
                <span className="month">EXCLUSIVE OF ALL TAXES</span>
                </span>
              </div>
              <div className="pricing-content">
                <ul>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Campaign - 2</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>AD Groups - 6</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Ad Copies - Maximum 10</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>No. of Keywords-50</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Search Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Display Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Gmail Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Video Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Shopping Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>App Promotion Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Remarketing List</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Conversion Tracking</li>
                  <li className="sub-color font-weight-bold text-center">PPC CAMPAIGN MANAGEMENT</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Competitor Analysis - Yes</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Keyword Optimization - Yes</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Ad Copy Optimization - Yes</li>
                  <li className="sub-color font-weight-bold text-center">VALUE ADDS</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>ROI Analysis</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Landing Page Optimization</li>
                  <li className="sub-color font-weight-bold text-center">REPORTING</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Reports - Monthly</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Dedicated Account Manager</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Support - Email, Chat</li>
                </ul>
                <a href="#" className="read">Get Started Now</a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 price-style">
            <div className="pricingTable10">
              <div className="pricingTable-header">
                <h3 className="heading">Enterprise PPC Packages
                </h3>
                <span className="price-value">
                <span className="currency">₹</span>30,000 
                <span className="month">/monthly</span><br />
                <span className="month">EXCLUSIVE OF ALL TAXES</span>
                </span>
              </div>
              <div className="pricing-content">
                <ul>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Campaign - 5</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>AD Groups - 10</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Ad Copies - Maximum 15</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>No. of Keywords-75</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Search Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Display Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Gmail Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Video Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Shopping Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>App Promotion Ads</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Remarketing List</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Conversion Tracking</li>
                  <li className="sub-color font-weight-bold text-center">PPC CAMPAIGN MANAGEMENT</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Competitor Analysis - Yes</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Keyword Optimization - Yes</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Ad Copy Optimization - Yes</li>
                  <li className="sub-color font-weight-bold text-center">VALUE ADDS</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>ROI Analysis</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Landing Page Optimization</li>
                  <li className="sub-color font-weight-bold text-center">REPORTING</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Reports - Monthly</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Dedicated Account Manager</li>
                  <li><i className="fa fa-check  pr-2" aria-hidden="true"></i>Support - Email, Chat</li>
                </ul>
                <a href="#" className="read">Get Started Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1> Why Hire BFC Softtech’s <span className="sub-color"> Pay-Per-Click Management </span> Services? </h1>
          </div>
        </div>
        <div className="col-md-12 text-center ">
          <p className="short-desc">We are team of experienced professionals who can build and manage your PPC campaigns with practiced ease, ensuring that your business goals are met within your budget. We offer comprehensive PPC management services that are sure to augment your online presence, covering keyword research, ad copywriting, and performance tracking. </p>
        </div>
      </div>
      <div className="row py-lg-5">
        <section className="web-banner mb-4">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/communicatio.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pt-3">
                            <h4 className="color-bluec ">Communication and Transparency </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">At BFC Softtech, we believe in being communicative with our clients. As a result, we provide frequent and reports detailing the results of your PPC campaigns, and we are always available to answer any concerns. Also, our experts collaborate directly with you to understand your business requirements before creating a PPC plan tailored to address your needs.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/beskope.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Focus on Improvement </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We believe in continuous improvement, which is why we continusously monitor the effectiveness of your PPC ads and take data-driven steps to optimise them. This ensures that your campaigns are performing optimally and producing the desired outcomes.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/account.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Solutions at a Low Cost</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Our skilled staff will create and implement a tailored PPC plan that is in line with your company's objectives and budget. We do extensive research to shortlist the most relevant and cost-effective keywords and ad placements for your campaign. This guarantees that your budget is used wisely and effectively.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </div>
    </div>
  </section>
 
</div>
</>
)
}
export default Ppc