import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import check from "../images/check.png";
const  E_Commerce_Development = () =>
{

return(
<>
<div className="web-page">
  <section className="">
    <div className="container px-0">
      <div className="row xs-column-reverse  align-items-stretch py-3">
        <div className="col-md-6 align-self-center">
          <div className="techUse-title ">
            <h1><span className="sub-color">Our E-commerce </span>Development Services</h1>
          </div>
          <p>BFC Softtech offers comprehensive e-commerce solutions to assist retail businesses, distributors, manufacturers, and wholesalers in improving consumer satisfaction, increasing sales, and expanding their reach. Continue reading to learn more about our customised e-commerce development services.</p>
        
          <Link to="/contact" className="btn btn-s">
          Let's Talk
          </Link>
        </div>
        <div className="col-md-6">
          <img src="../assets/img/solutions/e-comm.jpg" alt="" srcset="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  <section className="work-process py-5">
    <div className="container px-0">
      <div className="row">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1>Our <span className="sub-color">  E-Commerce </span> Development Process </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <img src="../assets/img/solutions/eCommerce dev.png" className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section className="py-lg-5">
    <div className="container px-0">
      <div className="row justify-content-center pt-4">
        <div className="col-md-12 text-center">
          <div className="techUse-title text-center">
            <h1>Why You Should Opt for <span className="sub-color">  E-commerce Development </span> Services</h1>
          </div>
          <p className="short-desc">E-commerce has changed the way companies do business and interact with their customers. It has opened up new avenues for businesses to access a broader audience and produce sales. Also, quality e -commerce development services can help you establish a strong online presence.<br/>
Listed below are some of the benefits of employing an e-commerce development service. </p>
        </div>
      </div>
      <div className="row why-go pb-4">
        <div className="col-md-4 ml-3 ba">
          <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Increased Reach </h4>
            <p>
            E-commerce platforms allow businesses to reach a larger audience more easily, making their products and services available to clients at all times, regardless of their location.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Improved Consumer Experience </h4>
            <p>
            A competent e-commerce development service provider can help you create user-friendly, visually appealing websites that enhance customer experience. A pleasant customer experience almost always leads to increased client loyalty and repeat business.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Streamlined Operations</h4>
            <p>
            Many processes, such as order processing, payment processing, and inventory management, are automated on e-commerce platforms, making it easier to run your business operations.
            </p>
          </div>
          </span>
        </div>
        {/* <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Enhanced Security</h4>
            <p>
            E-commerce development services prioritise customer data and transaction security. This is accomplished by utilising secure payment gateways, SSL certificates, and encrypted data storage.
            </p>
          </div>
          </span>
        </div> */}
     
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1>  Why Hire <span className="sub-color">BFC Softtech</span> as Your <span className="sub-color">  E-commerce Development </span> Partner?</h1>
          </div>
        </div>
        <div className="col-md-12 text-center ">
          <p className="short-desc">BFC Softtech is a premier e-commerce development company that offers comprehensive e-commerce solutions to businesses of all kinds. Our developers and designers have a thorough understanding of the e-commerce industry and are dedicated to assisting businesses in achieving their objectives through innovative, out-of-the-box solutions.
Here are a few reasons why you should work with BFC Softtech.</p>
        </div>
      </div>
      <div className="row py-5">
        <section className="web-banner mb-4">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/expert.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pt-3">
                            <h4 className="color-bluec ">Customized Services </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Our e-commerce solutions are tailored to your company's specific requirements. We take the time needed to understand your business goals and develop solutions that are specifically suited to help you accomplish them.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/dev.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title ">
                            <h4 className="color-bluec">Expertise in Emerging Innovations</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">BFC Softtech is committed to staying current with latest e-commerce technologies. We have vast expertise using new and emerging technologies to develop innovative e-commerce solutions that separate our clients from the competition.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/solutions.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title ">
                            <h4 className="color-bluec">Flagship Designs</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We understand the significance of designing visually appealing and user-friendly e-commerce websites. Consequently, our designers produce websites that are not only visually appealing but also practical.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/security.png" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title ">
                            <h4 className="color-bluec">Data Security</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">BFC Softtech places a high priority on the security of client data and transactions. As a result, our e-commerce solutions are designed with security in mind, incorporating secure payment gateways, SSL certificates, and encrypted data storage.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </div>
    </div>
  </section>
 
</div>
</>
)
}
export default  E_Commerce_Development