import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import check from "../images/check.png";
const Digital_Marketing = () =>
{

return(
<>
<div className="web-page">
  <section className="">
    <div className="container px-0">
      <div className="row  xs-column-reverse  align-items-stretch">
        <div className="col-md-6 align-self-center">
          <div className="techUse-title ">
            <h1><span className="sub-color"> Digital Marketing  </span> Services</h1>
          </div>
          <p className="text-justify">We are a team of experienced professionals dedicated to helping businesses grow their online presence through effective digital marketing strategies. We elevate your digital marketing game, ensuring that you reach out to your target audience, thereby achieving your business objectives most cost-effectively.</p>
          
          <Link to="/contact" className="btn btn-s">
          Let's Talk
          </Link>
        </div>
        <div className="col-md-6">
          <img src="../assets/img/solutions/dm.jpg" alt="" srcset="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
 
  <section className="py-lg-5">
    <div className="container px-0">
      <div className="row justify-content-center pt-4">
        <div className="col-md-12 text-center">
          <div className="techUse-title text-center">
            <h1>Our <span className="sub-color"> Digital Marketing  </span> Services</h1>
          </div>
          <p className="short-desc">The current market dynamic mandates that businesses keep adapting to the upcoming technological advances so they stay ahead of the competition. With more and people gaining access to the internet for exploring products and services, having a strong online presence is no longer an option — it's a necessity. This is where our digital marketing services can help. We provide a variety of services to help businesses develop a lasting online identity, in-turn allowing them to connect with their target audience, and achieve their business goals. </p>
        </div>
      </div>
      <div className="row why-go pb-4">
        <div className="col-md-4 ml-3 ba">
          <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Custom Marketing Strategies </h4>
            <p>
            A one-size-fits-all approach to digital marketing simply does not work because each business’s needs are unique. That's why we take out the time needed to understand your company's goals, target audience, and competitors before developing a marketing strategy that will help you stand out from the crowd. 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">SEO </h4>
            <p>
            SEO (search engine optimization) is an important component of any digital marketing plan. Our SEO services are aimed at assisting you in increasing your website's exposure on search engines such as Google and Bing, consequently driving more organic traffic to your website.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Social Media Marketing</h4>
            <p>
            Social media is an excellent tool for reaching out to potential customers promoting brands. Our social media marketing services will help you build a strong online presence, helping you engaging your target audience, and drive more traffic to your website. 
            </p>
          </div>
          </span>
        </div>
        </div>
        <div className="row why-go justify-content-center">
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Content Marketing </h4>
            <p>
            Any successful digital marketing strategy is incomplete without high-quality, engaging content. Our content marketing services can assist you in developing a content strategy that engages your target audience and converts leads into sales.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">PPC Advertising</h4>
            <p>
            Pay-per-click (PPC) advertising is be a very useful technique for driving visitors and conversions to your website. Our PPC advertising services help you develop focused campaigns that provide results and enhance your return on investment (ROI).
            </p>
          </div>
          </span>
        </div>
    
      </div>
    </div>
  </section>
 
  <section>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1> Why Choose <span className="sub-color">BFC Softtech</span> as your <span className="sub-color"> Digital Marketing </span> Agency? </h1>
          </div>
        </div>
        <div className="col-md-12 text-center ">
          <p className="short-desc">Over the years, BFC Softtech has provided top-notch digital marketing services to businesses and companies of all sizes, thereby building its reputation as a trusted and reliable digital marketing partner. With a team of highly experienced and skilled professionals at our disposal, we offer a wide range of services including SEO, PPC, social media marketing, email marketing, content marketing, and more. </p>
        </div>
      </div>
      <div className="row py-lg-5">
        <section className="web-banner mb-4">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/expert.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pt-3">
                            <h4 className="color-bluec ">Result-Oriented Approachs </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">One of the main reasons to choose us as your digital marketing agency is our result-oriented approach. We believe in achieving measurable results and work towards achieving them through relentless perseverance. We understand the unique needs of each client and offer customised solutions that cater to those specific needs.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/dev.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Innovation and Technology</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Another reason to choose BFC Softtech is our focus on innovation and technology. We constantly upgrade our skills and stay up-to-date with the latest trends in digital marketing. This ensures that our clients get access to the latest and most effective digital marketing strategies.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/support.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">24/7 Customer Service</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We are committed to providing excellent customer service, which is why we maintain regular communication with our clients, keeping them updated on the progress of their digital marketing campaigns. 
</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </div>
    </div>
  </section>
 
</div>
</>
)
}
export default Digital_Marketing