import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import check from "../images/check.png";
const Laravel_Development = () =>
{

return(
<>
<div className="web-page">
  <section className="">
    <div className="container px-0">
      <div className="row xs-column-reverse  align-items-stretch py-4">
        <div className="col-md-6 align-self-center">
          <div className="techUse-title">
            <h1><span className="sub-color">Laravel </span>Development</h1>
          </div>
          <p>Our Laravel Development Process is engineered to ensure high-quality output for our clients. By following strict development practices, we are able to develop applications that scalable, reliable, and easy to maintain. </p>
          <Link to="/contact" className="btn btn-s">
          Let's Talk
          </Link>
        </div>
        <div className="col-md-6">
          <img src="../assets/img/solutions/laravel.jpg" alt="" srcset="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  <section className="work-process py-5">
    <div className="container px-0">
      <div className="row">
        <div className="col-md-12">
          <div className="techUse-title text-center ">
            <h1>Our <span className="sub-color"> Laravel
              </span> Development Process 
            </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <img src="../assets/img/solutions/IT Process.png" className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section className="py-lg-5">
    <div className="container px-0">
      <div className="row justify-content-center pt-4">
        <div className="col-md-12 text-center">
          <div className="techUse-title text-center">
            <h1>Why you should opt for <span className="sub-color"> Laravel Application Development</span> 
            </h1>
          </div>
          <p className="short-desc">Laravel is a widely-used PHP framework known for its ease of use and robustness. Here are some reasons why you should opt for Laravel application development. </p>
        </div>
      </div>
      <div className="row why-go pb-4">
        <div className="col-md-4 ml-3 ba">
          <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Quick Development </h4>
            <p>
            Laravel facilitates fast app development strategy, helping developers create high-quality apps quickly. It reduces time and effort by using pre-built functionality and libraries. 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Elegant Syntax </h4>
            <p>
            The syntax of Laravel is simple and easy to comprehend, making the code clean and easily readable, in turn reducing errors and facilitating code maintenance. 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Robust Security Features</h4>
            <p>
            Laravel supports a number of security features, including hashed passwords, protection against SQL injections, and cross-site scripting (XSS) threats, contributing to the protection of web applications against potential security threats and vulnerabilities.
            </p>
          </div>
          </span>
        </div>
        </div>
        <div className="row why-go justify-content-center">
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Built-in Tools and Features </h4>
            <p>
            Laravel comes equipped with built-in tools that make web development more efficient and enjoyable. These include Artisan command-line interface, Eloquent ORM, Blade templating engine, and more. 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Active Community Support </h4>
            <p>
            Laravel has a large and active community of developers who contribute to the framework and provide support to other developers. This ensures that the framework is constantly evolving and improving.
            </p>
          </div>
          </span>
        </div>
       
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1>  Why Choose <span className="sub-color">BFC Softtech</span> as your <span className="sub-color"> Laravel Development </span> Company? 
             
            </h1>
          </div>
        </div>
        <div className="col-md-11 text-center ">
          <p className="short-desc">BFC Softtech is a top Laravel development firm that can provide customised solutions to meet your specific business requirements. Our skilled development team and commitment to quality make us a top choice for companies wishing to build high-quality web applications. Here are some of the benefits of hiring BFC Softtech as your Laravel development company: </p>
        </div>
      </div>
      <div className="row py-5">
        <section className="web-banner mb-4">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/dev.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pt-3">
                            <h4 className="color-bluec ">Proven Expertise 
                            </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Our team has years of proven experience in Laravel development and has worked on a wide range of projects. Furthermore, we are up to date on the newest trends and best practices in Laravel development and can provide solutions that are efficient, scalable, and safe.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/solutions.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pb-3">
                            <h4 className="color-bluec ">Customised Solutions
                            </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We recognise that every company has different needs. As a result, we provide bespoke Laravel solutions that are tailored to your individual requirements. We work directly with you to understand your company requirements and create solutions that meet your expectations.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/beskope.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pb-3">
                            <h4 className="color-bluec ">Dedicated Team
                            </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We have a dedicated team of developers at BFC Softtech who are committed to delivering high-quality solutions. Throughout the development process, our team is available to answer your questions and provide assistance.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/outs.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pb-3">
                            <h4 className="color-bluec ">Timely Delivery
                            </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We realise the significance of on-time delivery and work tirelessly to finish your project within the agreed-upon timeline. </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/account.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pb-3">
                            <h4 className="color-bluec ">Competitive Pricing
                            </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Our Laravel programming services are reasonably priced. We think that high-quality solutions should not be unaffordable, and we strive to provide our clients with value for money. </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </div>
    </div>
  </section>
  
</div>
</>
)
}
export default Laravel_Development