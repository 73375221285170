import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import Carousel from 'react-bootstrap/Carousel';
import Services_We_Offer from "../component/services-we-offer";
import What_Sets_Apart from "../component/what-sets-apart";
import 'animate.css';
import Otherd from "../component/What-we-offer-that-others-don't";
function Home() {   

return (
<>
{/* ========= Start Home Area ========= */}
<section className="home-banner">
  <Carousel>
    <Carousel.Item>
      <div className="banner-content">
        <div className="container px-lg-0 ">
          <div className="row xs-column-reverse align-items-stretch xs-t-column-reverse">
            <div className="col-lg-6 d-flex  mt-3 align-self-center">
              <div className="">
                <div className="banner-content">
                  <div className="banner-title typing-demo">
                    <div className="my-smc-3">
                      <h1 className="">Digital & Social Media </h1>
                      <h1>  Marketing Services  </h1>
                    </div>
                    <h5 className="py-lg-3 animate__animated animate__fadeInUp">SEO | Google Ads | Social Media | Content Writing</h5>
                  </div>
                  <div className="banner-disc">
                    <p className="mb-4">Hire a digital marketing agency that will set you apart from the competition, propelling your industry standing from “a player” to “the player”.</p>
                  </div>
                </div>
                <div className="banner-btn animate__animated animate__fadeInDown pt-3">
                  <Link to="/about" className="btnc default-btn-one">
                  About Us</Link>
                  <Link className="btnc default-btn" to="/contact">
                  Contact Us </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="banner-img">
                <div className="">
                  <img src="../assets/img/banner/website creative_2.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel.Item>
    <Carousel.Item>
      <div className="banner-content">
        <div className="container px-lg-0">
          <div className="row xs-column-reverse align-items-stretch xs-t-column-reverse">
            <div className="col-lg-6 d-flex  mt-3 align-self-center">
              <div className="">
                <div className="banner-content">
                  <div className="banner-title typing-demo home-c">
                    <div className="my-smc-3">
                      <h1 className="">Information Technology  </h1>
                      <h1>   & Automation Solutions  </h1>
                    </div>
                    <h5 className="py-lg-3 animate__animated animate__fadeInUp">Web Development  |  App Development | E-Commerce </h5>
                  </div>
                  <div className="banner-disc">
                    <p className="mb-4">Automation & computing solutions to help your business grow. Sit back & let our team take care of your design and development worries!</p>
                  </div>
                </div>
                <div className="banner-btn animate__animated animate__fadeInDown pt-3">
                  <Link to="/about" className="btnc default-btn-one">
                  About Us</Link>
                  <Link className="btnc default-btn" to="/contact">
                  Contact Us </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="banner-img">
                <div className="">
                  <img src="../assets/img/banner/banner-4.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel.Item>
  </Carousel>
</section>
{/* ======== End Home Area ======== */}  
{/* ======== What We Offer That Others Don't ======== */}        
<section className="bg-light-blue mt-n3">

  <Otherd />
</section>
{/* ========End  What We Offer That Others Don't ======== */}
{/* ====================  Sticky Section  Start ================== */}
<section className='stiky'>
  <What_Sets_Apart />
</section>
{/* ====================  Sticky Section End ================== */}
{/* ====================  what we offer Section End ================== */}
<section className="offer-c">
  <Services_We_Offer />
</section>
{/* ==================== portfolio ================== */}
<section className="home-portfolio-section pt-5 ">
  <div className="container ">
    <div className="row justify-content-center">
      <div className="col-md-6 pb-lg-4">
        <div className="portfolio-title text-center">
          <h1 className="py-3">   <span className="sub-color">Our </span>  Portfolio    </h1>
          <div className="portfolio-desc">
            <p className="short-desc">Take a look at our portfolio to check out projects we have delivered!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="portfolio py-lg-2 portfolio-bg">
    <div className="container ">
      <div className="row align-items-center justify-content-between pt-4">
        <div className="col-md-5">
          <div className="portfolio-info">
            <h3>Koshala Literature Festival</h3>
            <p>With BFC Softtech as its technological partner, KLF-Awadh promised to bring together the biggest names in the fields of Literature, Arts, Music, Politics and Filmmaking on one stage. Here’s how we helped them:</p>
            <ul className="pl-3">
              <li>Developed and optimized the Koshala Literature Festival’s official website.</li>
              <li>Brainstormed and created content for distribution on social media.</li>
              <li>Outlined and implemented an infallible social media and digital marketing campaign to ensure a healthy footfall at the festival.  </li>
            </ul>
            <Link to="/portfolio" className="btnc default-btn-one mt-4">
            View  Projects</Link>
          </div>
        </div>
        <div className="col-md-6">
          <div className="portfolio-img">
            <img src="../assets/img/portfolio/Koshala literature festival.png" className="img-fluid featured-banner" alt="" />
          </div>
          <p> <img src="../assets/img/animeted-icon/oval-3.png" className="img-fluid tenMT01 mobile-none" alt="" />
            <br />
            <img src="../assets/img/animeted-icon/combined-shape.png" className="img-fluid tenMT02 mobile-none" alt="" />
          </p>
        </div>
      </div>
    </div>
  </section>
  <section className="portfolio py-lg-2 portfolio-bg  bg-light-blue">
    <div className="container ">
      <div className="row align-items-center justify-content-between pt-4">
        <div className="col-md-5">
          <div className="portfolio-info">
            <h3>Orgeen </h3>
            <p>Orgeen is an agri-tech startup cultivating and distributing hydroponic and organic farm produce in and around Lucknow. Here’s how we helped them:</p>
            <ul className="pl-3">
              <li>Developed and optimized Orgeen’s official website.</li>
              <li>Designed and set up the company's e-commerce-enabled online store.</li>
              <li>Designed and developed an app to facilitate the sale of Orgeen products</li>
              <li>Brainstormed & created content for distribution on social media.</li>
            </ul>
            <Link to="/portfolio" className="btnc default-btn-one mt-4">
            View  Projects</Link>
          </div>
        </div>
        <div className="col-md-6">
          <div className="portfolio-img">
            <img src="../assets/img/portfolio/Orgeen.png" className="img-fluid featured-banner" alt="" />
          </div>
          <img src="../assets/img/animeted-icon/shape1.png" className="img-fluid dotted-circle mobile-none" alt="" />
          <br />
          <img src="../assets/img/animeted-icon/shape3.png" className="img-fluid dotted-circle3 mobile-none" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section className="portfolio py-lg-2  portfolio-bg">
    <div className="container ">
      <div className="row align-items-center justify-content-between pt-4">
        <div className="col-md-5">
          <div className="portfolio-info">
            <h3>BFC Publications </h3>
            <p>BFC Publications is a publishing house functioning out of Lucknow, helping publish authors with a penchant for delivering brilliant works of literature. Here’s how we helped them:</p>
            <ul className="pl-3">
              <li>Developed and optimized the company’s official website.</li>
              <li>Designed and developed the company’s e-commerce platform and online bookstore store.</li>
              <li>Designed and developed an app to facilitate online publishing at the company’s behest.</li>
              <li>Outlined and implemented an infallible social media and digital marketing campaign on the company’s behalf.</li>
              <li>Brainstormed & created content for marketing the company on social media.</li>
            </ul>
            <Link to="/portfolio" className="btnc default-btn-one mt-4">
            View  Projects</Link>
          </div>
        </div>
        <div className="col-md-6">
          <div className="portfolio-img">
            <img src="../assets/img/portfolio/Publications.png" className="img-fluid featured-banner" alt="" />
          </div>
          <p> <img src="../assets/img/animeted-icon/11.png" className="img-fluid shape1 mobile-none" alt="" />
            <br />
            <img src="../assets/img/animeted-icon/12.png" className="img-fluid shape2 mobile-none" alt="" />
          </p>
        </div>
      </div>
    </div>
  </section>
  <section className="portfolio py-lg-2 portfolio-bg  bg-light-blue">
    <div className="container ">
      <div className="row align-items-center justify-content-between pt-4">
        <div className="col-md-5">
          <div className="portfolio-info">
            <h3>BFC Capital</h3>
            <p>BFC Capital is one of the biggest mutual funds distributors in North India that has been providing client-centric wealth management services for over two decades. Here’s how we helped the company expand its business:</p>
            <ul className="pl-3">
              <li>Developed and optimized the company’s official website.</li>
              <li>Designed and set up the company’s e-commerce funnel.</li>
              <li>Designed and developed a fintech app to facilitate online investments at the company’s behest.</li>
              <li>Designed and developed the company’s flagship algorithm for recommending mutual fund schemes based on user inputs, namely Prodigy Pro.</li>
              <li>Outlined and implemented an infallible social media and digital marketing campaign on the company’s behalf.</li>
              <li>Brainstormed & created content for marketing the company on social media.</li>
            </ul>
            <Link to="/portfolio" className="btnc default-btn-one mt-4">
            View  Projects</Link>
          </div>
        </div>
        <div className="col-md-6">
          <div className="portfolio-img">
            <img src="../assets/img/portfolio/Capital.png" className="img-fluid featured-banner" alt="" />
          </div>
          <img src="../assets/img/animeted-icon/shape1.png" className="img-fluid dotted-circle mobile-none" alt="" />
          <br />
          <img src="../assets/img/animeted-icon/shape3.png" className="img-fluid dotted-circle3 mobile-none" alt="" />
        </div>
      </div>
    </div>
  </section>
</section>
{/* <div className="testimonial py-3 mb-lg-5">
  <div className="container ">
    <div className="row justify-content-center">
      <div className="col-md-6 pb-4">
        <div className="testimonial-title text-center">
          <h1 className="py-3">Success <span className="sub-color"> Stories </span></h1>
          <div className="testimonial-desc">
            <p className="short-desc">Have a look at the invaluable reviews we have earned.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <Carousel>
          <Carousel.Item interval={2000}>
            <div className="container ">
              <div className="row">
                <div className="col-md-12">
                  <div className="row align-items-center justify-content-between xs-column-reverse">
                    <div className="col-md-6">
                      <div className="quotes-img pb-4">
                        <img src="../assets/img/quotes-left.svg" className="img-fluid" alt="" />
                      </div>
                      <div className="quotes-heading pb-4">
                        <h2>Lorem Ipsum is simply dummy text</h2>
                        <ul className="quotes-rating d-flex list-style-none">
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                        </ul>
                      </div>
                      <div className="quotes-desc pb-4">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                      </div>
                      <div className="quotes-user">
                        <h3>Lorem Ipsum</h3>
                        <p>simply dummy text</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="quotes-userimg">
                        <img src="../assets/img/user.png" className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          {/* ========== */}
          {/* <Carousel.Item interval={2000}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="row align-items-center justify-content-between xs-column-reverse">
                    <div className="col-md-6">
                      <div className="quotes-img pb-4">
                        <img src="../assets/img/quotes-left.svg" className="img-fluid" alt="" />
                      </div>
                      <div className="quotes-heading pb-4">
                        <h2>Lorem Ipsum is simply dummy text</h2>
                        <ul className="quotes-rating d-flex list-style-none">
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                        </ul>
                      </div>
                      <div className="quotes-desc pb-4">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                      </div>
                      <div className="quotes-user">
                        <h3>Lorem Ipsum</h3>
                        <p>simply dummy text</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="quotes-userimg">
                        <img src="../assets/img/user1.png" className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item> */}
          {/* ============== */}
          {/* <Carousel.Item interval={2000}>
            <div className="container ">
              <div className="row">
                <div className="col-md-12">
                  <div className="row align-items-center justify-content-between xs-column-reverse">
                    <div className="col-md-6">
                      <div className="quotes-img pb-4">
                        <img src="../assets/img/quotes-left.svg" className="img-fluid" alt="" />
                      </div>
                      <div className="quotes-heading pb-4">
                        <h2>Lorem Ipsum is simply dummy text</h2>
                        <ul className="quotes-rating d-flex list-style-none">
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                          <li className="bg-rating">
                            <FaStar />
                          </li>
                        </ul>
                      </div>
                      <div className="quotes-desc pb-4">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                      </div>
                      <div className="quotes-user">
                        <h3>Lorem Ipsum</h3>
                        <p>simply dummy text</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="quotes-userimg">
                        <img src="../assets/img/user2.png" className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  </div>
</div>  */}
{/* ======== Start Blog Area ======== */}

{/* <section className="home-blog-section  pb-5">
  <div className="container ">
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="home-blog-title text-center pb-lg-4">
          <h1 className="py-3"><span className="sub-color">Our</span>  Blogs</h1>
          <div className="home-blog-desc">
            <p className="short-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text</p>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-4">
        <div className="blog-info">
          <div className="blog-img">
            <img src="../assets/img/blog/blog-1.jpg" className="img-fluid" alt="" />
          </div>
          <div className="blog-content pt-4 pb-1">
            <div className="blog-title">
              <h3>Lorem Ipsum is simply dummy text </h3>
            </div>
            <div className="blog-desc pb-1">
              <p className="text-justify">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
            </div>
            <div className="blog-btn">
              <a href="">Read More</a>
            </div>
            <hr />
            <div className="blog-date">
              <p>April 24, 2021</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="blog-info">
          <div className="blog-img">
            <img src="../assets/img/blog/blog-2.jpg" className="img-fluid" alt="" />
          </div>
          <div className="blog-content pt-4 pb-1">
            <div className="blog-title">
              <h3>Lorem Ipsum is simply dummy text </h3>
            </div>
            <div className="blog-desc pb-1">
              <p className="text-justify">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
            </div>
            <div className="blog-btn">
              <a href="">Read More</a>
            </div>
            <hr />
            <div className="blog-date">
              <p>April 24, 2021</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="blog-info">
          <div className="blog-img">
            <img src="../assets/img/blog/blog-3.jpg" className="img-fluid" alt="" />
          </div>
          <div className="blog-content pt-4 pb-1">
            <div className="blog-title">
              <h3>Lorem Ipsum is simply dummy text </h3>
            </div>
            <div className="blog-desc pb-1">
              <p className="text-justify">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
            </div>
            <div className="blog-btn">
              <a href="">Read More</a>
            </div>
            <hr/>
            <div className="blog-date">
              <p>April 24, 2021</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</section> */}
{/* ======== End Blog Area ======== */}
</>
);
}
export default Home;