import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import check from "../images/check.png";
const Mobile_App_Development = () =>
{

return(
<>
<div className="web-page">
  <section className="">
    <div className="container px-0">
      <div className="row xs-column-reverse  align-items-stretch">
        <div className="col-md-6 align-self-center">
          <div className="techUse-title ">
            <h1><span className="sub-color">Mobile App </span>Development Services </h1>
          </div>
          <p className="text-justify">
          Our Developers are skilled at utilising cutting-edge technologies to develop unique and efficient mobile apps that provide a seamless user experience. We have the skills and expertise to turn your ideas into reality, whether they are for an iOS, Android, Flutter, or React Native app. Our developers are dedicated to creating apps that meet the specific needs of your company, guaranteeing that your customers are totally engaged with your brand.
          </p>
          <Link to="/contact" className="btn btn-s">
          Let's Talk
          </Link>
        </div>
        <div className="col-md-6">
          <img src="../assets/img/solutions/app.jpg" alt="" srcset="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  <section className="work-process py-5">
    <div className="container px-0">
      <div className="row">
        <div className="col-md-12">
          <div className="techUse-title text-center ">
            <h1>Our <span className="sub-color"> Mobile App </span> Development Process </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <img src="../assets/img/solutions/Mobile.png" className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section className="py-lg-5">
    <div className="container px-0">
      <div className="row justify-content-center pt-4">
        <div className="col-md-12 text-center">
          <div className="techUse-title text-center">
            <h1>Innovative Mobile App Solutions for Every <span className="sub-color"> Industry </span> </h1>
          </div>
          <p className="short-desc">BFC Softtech specialises in developing creative mobile app solutions for businesses of all sizes. Our professional software developers collaborate closely with our clients to better understand their unique requirements and create tailored solutions that propel their business ahead.
Our Industry-Specific Solutions </p>
        </div>
      </div>
      <div className="row why-go pb-4">
        <div className="col-md-4 ml-3 ba">
          <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Healthcare</h4>
            <p>
            We recognise how vital it is to ensure the security and privacy of sensitive patient information. Our healthcare apps are built to meet the industry's stringent norms and criteria while also providing a user-friendly experience.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Retail</h4>
            <p>
            Having a well-designed mobile app can give you a considerable advantage in the competitive world of retail. Our retail apps are designed to improve your customers' shopping experiences, increase sales, and provide vital insights into customer behaviour. 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Finance</h4>
            <p>
            We recognise the significance of offering secure and dependable mobile solutions for the financial industry. Our finance solutions are intended to streamline procedures, boost efficiency, and offer financial institutions and their consumers with real-time access to information.
            </p>
          </div>
          </span>
        </div>
        {/* <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Education </h4>
            <p>
            Technology is becoming increasingly significant in the sphere of education. Our apps are aimed at facilitating communication and collaboration among educators while also improving the learning experience for students and teachers.
            </p>
          </div>
          </span>
        </div> */}
       
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1> Why Choose <span className="sub-color">BFC Softtech</span> for Your <span className="sub-color"> Mobile App Development </span> Needs? </h1>
          </div>
        </div>
        <div className="col-md-12 text-center ">
          <p className="short-desc">Mobile applications have become an essential component of modern businesses, providing clients with a seamless and simple way to communicate with your brand. Having a mobile app can help you reach your goals of improving client engagement, increasing brand awareness, or streamlining your processes. Look no further than BFC Softtech if you're looking for a dependable and professional mobile app development business. Here are a few reasons why: </p>
        </div>
      </div>
      <div className="row py-5">
        <section className="Mobile App-banner mb-4">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/dev.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pt-3">
                            <h4 className="color-bluec ">Expertise in multiple platforms</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Our development team has vast expertise in creating apps for a wide range of platforms, including iOS, Android, Flutter, and React Native. This implies that we can create custom-made apps that match the specific demands of your organisation, regardless of the platform.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/expert.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title ">
                            <h4 className="color-bluec ">Focus on User Experience</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We realise how important a pleasant user experience is for the success of your mobile app. This is why we prioritize the development of mobile apps that offer not just functionality, but also an intuitive and enjoyable user experience. Our aim is to provide customers with an effortless and seamless engagement with your brand through our mobile applications.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/solutions.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title ">
                            <h4 className="color-bluec ">Affordable Solutions</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">At BFC Softtech, we think that mobile app development services should be accessible to businesses of all sizes. That is why we offer affordable solutions that enable you to reach your goals without excessive financial strain. Our goal is to make top-quality mobile app development services accessible to all sorts of businesses, without sacrificing on quality or affordability.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/support.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title ">
                            <h4 className="color-bluec ">Dedicated Support</h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We believe in building lasting partnerships with our clients and are dedicated to offering lasting support to ensure the optimal performance of your mobile app. Our commitment to our clients extends beyond the development stage, and we strive to be a reliable and dependable partner throughout the life of your mobile application.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </div>
    </div>
  </section>
  
</div>
</>
)
}
export default Mobile_App_Development