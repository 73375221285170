import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaTimes } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
function Career_Apply() {
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
return (
<>
<a href="#" class="btn-s rounded pb-2 pt-1 text-poppins" onClick={handleShow}>Apply<i class="fa fa-arrow-right ml-2 app-i"></i></a>
<Modal show={show} onHide={handleClose} className=""  centered>
  <Modal.Header className='bg-light'>
    <div className="col-md-8 px-0">
      <h5 className='text-poppins'>Apply for this job</h5>
    </div>
    <div className="col-md-4 text-right">
      <a href='#' className="text-danger" onClick={handleClose}>
        <FaTimes/>
      </a>
    </div>
  </Modal.Header>
  <Modal.Body>
    <div className="apply-main">
      <Form>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Full Name</Form.Label>
          <Form.Control type="text" placeholder="Full Name" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="mobile">
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control type="text" placeholder=" Your Mobile Number" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email ID</Form.Label>
          <Form.Control type="email" placeholder="Your Email" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="post">
          <Form.Label>Post Applied For?</Form.Label>
          <Form.Control type="text" placeholder="" />
        </Form.Group>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Upload Resume / CV</Form.Label>
          <input type="file" name="formFile" id="formFile" className='' />
        </Form.Group>
        <Form.Group className="text-right " controlId="exampleForm.ControlInput1">
          <a href='#' type='submit' className='btn-s py-2 rounded'>Submit</a>
        </Form.Group>
      </Form>
    </div>
  </Modal.Body>
</Modal>
</>
);
}
export default Career_Apply