import React,{useEffect} from "react";
import {Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import check from "../images/check.png";
const Nodejs_Development = () =>
{

return(
<>
<div className="web-page">
  <section className="">
    <div className="container px-0">
      <div className="row  py-lg-5 align-items-stretch xs-column-reverse">
        <div className="col-md-6 py-4 align-self-center">
          <div className="techUse-title ">
            <h1><span className="sub-color">NodeJS </span>Development</h1>
          </div>
          <p>For your dream application, you can utilize the powerful and advanced node js development tool. This tool shall help create successful and smooth applications that deliver unmatched real time performance and complex network solutions. </p>
          <Link to="/contact" className="btn btn-s">
          Let's Talk
          </Link>
        </div>
        <div className="col-md-6">
          <img src="../assets/img/solutions/node.jpg" alt="" srcset="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  <section className=" bg-light-blue py-5">
    <div className="container px-0">
      <div className="row">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1>Our <span className="sub-color"> NodeJS </span> Development Process </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <img src="../assets/img/solutions/IT Process.png" className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section className="py-5">
    <div className="container px-0">
      <div className="row justify-content-center pt-4">
        <div className="col-md-12 text-center">
          <div className="techUse-title text-center">
            <h1>Why you should Go for <span className="sub-color"> Node Js Development </span>  Services</h1>
          </div>
          <p className="short-desc">A top level network programming platform, Node JS development allows to build scalable applications in JS. Node.js is an effective cross-platform and open-source library that is used for running web apps outside the browser of the client. This service can assist to the growth of the business through better audience engagement and fulfilling goals.  </p>
        </div>
      </div>
       <div className="row why-go pb-4">
        <div className="col-md-4 ml-3 ba">
          <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Data Streaming Apps </h4>
            <p>
            There has been more of digital content creation and consumption and data streaming applications perform really well for business growth. Through the application of the stream module used in Node.js web app, developers will process massive data into small packages and consecutively.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Language sharing </h4>
            <p>
            Node js development can be used for front-end as well as back-end development. This tool enables consistent flow of language on the application. Node js allows to exchange code in client and server applications that results in improved communication while making stack development easier and accessible. 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Scalable </h4>
            <p>
            Node js development offers huge scaling for complicated applications. Node JS development can easily deal with multiple connections with high output.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Packages  </h4>
            <p>
            Node js development offers various open-source Node JavaScript packages through which clients can save their time and efforts. 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Easy maintenance  </h4>
            <p>
            Node js development is fantastic for developers as they can write frontend along with backend can be written in JS. This makes the tool more convenient than Python’s Django and other frameworks.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Cross-platform</h4>
            <p>
            Cross-platform Node js compatibility allows the developers to create SaaS websites, real-time apps, and mobile apps through Node.JS development service.
            </p>
          </div>
          </span>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1>  Why Choose <span className="sub-color">BFC Softtech</span> as a <span className="sub-color"> NodeJS Development </span> Company? </h1>
          </div>
        </div>
        <div className="col-md-12 text-center ">
          <p className="short-desc">BFC Softtech provides various tech solutions and custom app development services to its clients from diverse industries. The developers have in-depth and advanced knowledge and understating of node js development.</p>
        </div>
      </div>
      <div className="row py-lg-5">
        <section className="web-banner mb-4">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/dev.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pt-3">
                            <h4 className="color-bluec ">Custom development  </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Utilize the power of node js development offered by BFC Softtech to create modern customized apps for mobile and web. The service will address all the unique business challenges and provide easy solutions. </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/expert.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Plug-in development </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">The trained team of developers at BFC Softtech will build plugins for modifications in the codes and effortlessly add latest features and functionalities for improved performance and output.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/outs.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Project outsourcing </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">Leverage skilled developers at BFC Softtech with node js expertise to develop software through node js library that suit different types of businesses.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/account.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Cost savings  </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">BFC Softtech receives numerous inquiries for dedicated Node.js service because of cost-effectiveness. We will conduct a detailed cost-benefit analysis for each client and the project costs less than other solutions out there such as PHP and Java servers.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/migration.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">App migration </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">If clients need to migrate their existing system to node js, BFC Softtech is here to help. With a seasoned team of experts, clients and smoothly move their app data, app setup, and configuration.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </div>
    </div>
  </section>
 
</div>
</>
)
}
export default Nodejs_Development