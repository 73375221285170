import React from "react";
import {Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import check from "../images/check.png";
const Web_Development = () =>
{
 
return(
<>
<div className="web-page">
  <section className="">
    <div className="container px-0">
      <div className="row xs-column-reverse  align-items-stretch pt-3">
        <div className="col-md-6 align-self-center">
          <div className="techUse-title ">
            <h1><span className="sub-color">Web Development </span>Company</h1>
          </div>
          <p className="text-justify"> We believe that an excellent website is a strong tool for businesses, and we strive to give the finest quality web development services to our clients. From start to finish, we work closely with our clients to ensure their vision is brought to life and their website exceeds their expectations.</p>
          <Link to="/contact" className="btn btn-s">
          Let's Talk
          </Link>
        </div>
        <div className="col-md-6">
          <img src="../assets/img/solutions/web.jpg" alt="" srcset="" className="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  <section className="work-process py-5">
    <div className="container px-0">
      <div className="row">
        <div className="col-md-12">
          <div className="techUse-title text-center mb-2">
            <h1>Our <span className="sub-color"> Web </span> Development Process </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <img src="../assets/img/solutions/WebDev.png" className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section className="py-lg-5">
    <div className="container px-0">
      <div className="row justify-content-center pt-4">
        <div className="col-md-12 text-center">
          <div className="techUse-title text-center">
            <h1>Advantages of Hiring a <span className="sub-color"> Web Development  </span>  Company</h1>
          </div>
          <p className="short-desc">Roping-in a web development company can boost your brand's online visibility and drive business growth through custom designs and solutions tailored to meet your specific needs. </p>
        </div>
      </div>
      <div className="row why-go pb-4">
        <div className="col-md-4 ml-3 ba">
          <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Access to Experienced & Specialized Web Developers</h4>
            <p>
            By hiring a web development company you gain access to an experienced web development team, ensuring the smooth execution and delivery of projects with a high degree of technical expertise.
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Quality Assurance & Project Management Support </h4>
            <p>
            Quality assurance and project management assistance are provided to ensure that your website is completed on time, within budget, and is of the highest standard. 
            </p>
          </div>
          </span>
        </div>
        <div className="col-md-4 ml-3 ba">
        <span>
          <div className="hover-icon">
          <img src={check} alt="" srcset="" className="img-fluid" />
            <h4 className="color-bluec pt-3">Year-Round Technical Support</h4>
            <p>
            Web development companies offer year-round technical support, including ongoing maintenance and technical assistance, to ensure your website or app's seamless operation and longevity.
            </p>
          </div>
          </span>
        </div>
       
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="techUse-title text-center">
            <h1> Why You Should Choose<span className="sub-color"> BFC Softtech </span>As Your    <span className="sub-color">Web Development</span> Company? </h1>
          </div>
        </div>
        <div className="col-md-12 text-center ">
          <p className="short-desc">When it comes to web development, choosing BFC Softtech as your web development company can bring numerous advantages to your company. Our team has the ability and know-how needed to offer bespoke web and software solutions. And, with our 24-hour technical assistance, you can rest assured that any post-launch concerns will be remedied quickly and efficiently.</p>
        </div>
      </div>
      <div className="row py-lg-5">
        <section className="web-banner mb-4">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/beskope.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch ">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title pt-3">
                            <h4 className="color-bluec ">Collaborative Approach </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We work closely with our clients to ensure their vision is realized and expectations exceeded.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/solutions.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Bespoke Solutions </h4>
                          </div>
                          <div className="banner-disc">
                            <p className="">We offer tech and automation solutions tailored to meet your business’s specific needs.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/communicatio.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">Ongoing Communication </h4>
                          </div>
                          <div className="banner-disc">
                            <p className=""> We keep you informed about the progress of your project, so you’re actively aware of what’s going on behind the scenes, in turn ensuring 100% customer satisfaction.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="banner-content">
                <div className="container px-0">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="banner-img">
                        <div className="">                       
                          <img src="../assets/img/solutions/new/support.jpg" className="img-fluid" alt="" />                           
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 d-flex align-items-stretch">
                      <div className="align-self-center">
                        <div className="banner-content">
                          <div className="banner-title">
                            <h4 className="color-bluec ">24/7 Technical Support </h4>
                          </div>
                          <div className="banner-disc">
                            <p className=""> We offer around the clock technical assistance to our clients, making sure their business never goes offline..</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </section>
      </div>
    </div>
  </section>
 
</div>
</>
)
}
export default Web_Development